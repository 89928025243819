import { Typography } from '@material-ui/core'
import React, { useEffect, useRef, useState } from 'react'

import PendingButton from '../../atoms/PendingButton/PendingButton'
import FormElementsGroup from '../../organisms/FormElementsGroup'
import PopupBox from '../../popup/PopupBox'
import PopupContent from '../../popup/PopupContent'
import PopupHeader from '../../popup/PopupHeader'

export default function WizardPopup({ api, onSuccess, onClose }) {
  const [popupData, setPopupData] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [externalErrors, setExternalErrors] = useState({})
  const [isFetchingCount, setIsFetchingCount] = useState(false)
  const [formData, setFormData] = useState({
    minActivityScore: 0,
    maxActivityScore: 0,
    maxPrevWonPrizes: 0,
    numWinners: 0,
    bundle: '',
    excludedPromotions: [],
    // hardcoded for now, interactivty will come with a later ticket
    // TODO include the interactive winner picking
    mode: 'non-interactive',
  })

  const formRef = useRef(null)
  const promotionId = window.location.pathname.split('/').at(-1)

  useEffect(() => {
    const fetchPromoData = async () => {
      const { request } = api.queryWinnerPickingData(promotionId)
      const { data } = await request
      setPopupData(data)
    }
    // validate that we are fetching for an objectid
    if (!/^[a-fA-F0-9]{24}$/.test(promotionId)) return
    fetchPromoData()
  }, [api, promotionId])

  const handleInputChange = (id, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }))
  }

  const handleFetchParticipants = async () => {
    setIsFetchingCount(true)
    try {
      const normalizedFormData = {
        ...formData,
        excludedPromotions: formData.excludedPromotions?.map((item) => item.value).join(','),
      }

      const queryString = new URLSearchParams(normalizedFormData).toString()
      const { request } = api.queryWinnerPickingCount(promotionId, queryString)
      const { data } = await request

      setPopupData((prev) => ({
        ...prev,
        potentialWinners: data.totalParticipations,
      }))
    } catch (error) {
      console.error('Error fetching participants count:', error)
    } finally {
      setIsFetchingCount(false)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Ensure the form is valid
    if (!formRef.current || !formRef.current.checkValidity()) {
      return
    }

    setIsSubmitting(true)
    setExternalErrors({})

    try {
      const data = {
        ...formData,
        bundle: formRef.current['bundle']?.value,
        // hardcoded for now, interactivty will come with a later ticket
        // TODO include the interactive winner picking
        mode: 'non-interactive',
      }
      const { request } = api.submitWinnerPickingData(promotionId, data)
      await request

      onSuccess && onSuccess()
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.error &&
        error.response.data.error.inputError
      ) {
        setExternalErrors({
          [error.response.data.error.inputError[0]]: error.response.data.error.message,
        })
      }
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <PopupBox onClose={onClose}>
      <PopupHeader />
      <PopupContent>
        <Typography variant="h2">Winner Picking Wizard</Typography>

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '20px',
          }}
        >
          <div style={{ flex: 1, textAlign: 'center' }}>
            <Typography variant="subheading">
              Total Participations: <strong>{popupData.totalParticipations}</strong>
            </Typography>
          </div>
          <div style={{ flex: 1, textAlign: 'center' }}>
            <Typography variant="subheading">
              Eligible Winners: <strong>{popupData.potentialWinners}</strong>
            </Typography>
          </div>
        </div>

        <form noValidate ref={formRef} onSubmit={handleSubmit}>
          <FormElementsGroup
            externalErrors={externalErrors}
            elements={[
              {
                id: 'minActivityScore',
                name: 'minActivityScore',
                required: true,
                min: 0,
                placeholder: 'Minimum activity score',
                type: 'integer',
                helperText: 'Ignored if 0',
                onChange: handleInputChange,
                value: formData.minActivityScore,
              },
              {
                id: 'maxActivityScore',
                name: 'maxActivityScore',
                required: true,
                min: 0,
                placeholder: 'Maximum activity score',
                helperText: 'Ignored if 0',
                type: 'integer',
                onChange: handleInputChange,
                value: formData.maxActivityScore,
              },
              {
                id: 'maxPrevWonPrizes',
                name: 'maxPrevWonPrizes',
                required: true,
                min: 0,
                placeholder: 'Maximum previously won prizes',
                type: 'integer',
                onChange: handleInputChange,
                value: formData.maxPrevWonPrizes,
              },
              {
                id: 'numWinners',
                name: 'numWinners',
                required: true,
                min: 0,
                placeholder: 'Number of winners',
                type: 'integer',
                onChange: handleInputChange,
                value: formData.numWinners,
                helperText: 'If 0, you must pick all winners manually',
              },
              {
                id: 'bundle',
                name: 'bundle',
                placeholder: 'Bundle',
                type: 'enum',
                data: popupData.bundles,
                onChange: handleInputChange,
                value: formData.bundle,
                helperText: 'Required',
              },
              {
                id: 'excludedPromotions',
                name: 'excludedPromotions',
                placeholder: 'Exclude winners from previous promotions',
                type: 'multiple',
                data: popupData.allOtherPromotions,
                onChange: handleInputChange,
                value: formData.excludedPromotions,
              },
            ]}
          />
          <div align="center" style={{ margin: 8 }}>
            <PendingButton
              variant="contained"
              color="default"
              pending={isFetchingCount}
              onClick={handleFetchParticipants}
            >
              Update Eligibility
            </PendingButton>
          </div>
          <div align="center" style={{ margin: 8 }}>
            <PendingButton type="submit" variant="contained" pending={isSubmitting} color="secondary">
              Submit
            </PendingButton>
          </div>
        </form>
      </PopupContent>
    </PopupBox>
  )
}

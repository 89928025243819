import queryString from 'query-string'

export default class AdminApi {
  /**
   * @param {ApiConnector} connector
   */
  constructor(connector) {
    this.connector = connector
  }

  createModelEntry = (url, data) => {
    return this.connector.post(url, data ? { data } : undefined)
  }

  createModelEntryFromFile = (url, file) => {
    const formData = new FormData()
    formData.append('fileData', file)

    return this.connector.put(url, formData)
  }

  updateModelEntry = (path, data, method = 'PATCH', lang) => {
    return this.connector[method.toLowerCase()](
      path,
      {
        set: data,
      },
      lang ? { headers: { 'x-language': lang } } : undefined,
    )
  }

  uploadInlineButtonData = (url, file, settings) => {
    const formData = new FormData()
    formData.append('data', file)
    formData.append('settings', JSON.stringify(settings || {}))
    return this.connector.put(url, formData)
  }

  uploadModelData = (url, file) => {
    const formData = new FormData()
    formData.append('csvData', file)
    return this.connector.put(url, formData)
  }

  downloadModel = (url) => {
    window.location.href = `${this.connector.baseUrl}${url}`
  }

  login = (data) => {
    return this.connector.post(`/@/auth`, data)
  }

  versionInfo = () => {
    return this.connector.get(`/@/versionInfo`)
  }

  versionUpdate = () => {
    return this.connector.get(`/@/versionUpdate`)
  }

  logout = async () => {
    let { request } = this.connector.post(`/@/logout`)
    await request
    window.location.reload(true)
  }

  resetPassword = (data) => {
    return this.connector.post(`/@/resetPassword`, data)
  }

  setPassword = (data) => {
    return this.connector.post(`/@/setPassword`, data)
  }

  uploadMedia = (url, file, tags) => {
    const formData = new FormData()
    formData.append('uploadData', file)
    formData.append('uploadDataName', file.name)
    formData.append('tags', tags)
    return this.connector.post(url || '/@/media/upload', formData)
  }

  enhanceMedia = (url, _id, file, selectedLanguage, updateField) => {
    const formData = new FormData()
    formData.append('uploadData', file)
    formData.append('uploadDataName', file.name)
    return this.connector.post(url || `/@/media/${_id}/localise/${selectedLanguage}/${updateField}`, formData)
  }

  getMedias = (params = {}) => {
    const queryString = Object.keys(params)
      .filter((key) => params[key])
      .map((key) => `${key}=${params[key]}`)
      .join('&')

    const urlParameters = queryString.length > 4 ? `?${queryString}` : ''

    return this.connector.get(`/@/media${urlParameters}`)
  }

  getUserMedia = (id) => {
    return this.connector.get(`/@/userChallengeMedia/${id}`)
  }

  updateMedia = (_id, data) => {
    return this.connector.patch(`/@/media/${_id}`, data)
  }

  deleteMedia = (_id) => {
    return this.connector.delete(`/@/media/${_id}`)
  }

  getReferenceSuggestions = (collection, name, from, search) => {
    let query, queryInput
    query = queryString.stringify({ name, from })
    if (search) queryInput = queryString.stringify({ search })

    return this.connector.get(
      `/@/~/${collection}?context=reference${query !== undefined ? `&${query}` : ''}${
        queryInput !== undefined ? `&${queryInput}` : ''
      }`,
    )
  }

  download = (url) => {
    window.location.href = `${this.connector.baseUrl}${url}`
  }

  getModelListData = (collection, query) => {
    return this.connector.get(`/@/~/${collection}?${query}`)
  }

  getSegmentationOptions = () => {
    return this.connector.get(`/@/segmentationOptions`)
  }

  customizeColumns = (columns, modelName) => {
    return this.connector.put(`/@/customize/${modelName}`, { columns })
  }

  getSurveyQuestions = () => {
    return this.connector.get(`/@/surveyQuestions`)
  }

  getMongoAtlasJWT = () => {
    return this.connector.get(`/get-atlas-charts-token`)
  }

  queryWinnerPickingData = (promotionId) => {
    return this.connector.get(`/winner-picking-data/${promotionId}`)
  }

  queryWinnerPickingCount = (promotionId, filters) => {
    const queryParams = filters ? `?${filters}` : ''
    return this.connector.get(`/winner-picking-count/${promotionId}${queryParams}`)
  }

  submitWinnerPickingData = (promotionId, data) => {
    return this.connector.post(`/winner-picking-data/${promotionId}`, {
      data,
      headers: {
        'x-type': 'popup',
      },
    })
  }
}
